import { GiCoffeeMug,GiSkullInJar,GiDesk,Gi3DGlasses } from "react-icons/gi";

export const projects = [{
    name: 'Bean 2',
    description: '#a8edea → #fed6e3',
    icon: <GiCoffeeMug/>,
    css: 'linear-gradient(135deg, #242423 0%, #242423 100%)',
    height: 200,
  },
  {
    name: 'DyingCinema',
    description: '#f5f7fa → #c3cfe2',
    icon: <GiSkullInJar />,
    css: 'linear-gradient(135deg, #242423 0%, #242423 100%)',
    height: 400,
  },
  {
    name: 'AllyDesk',
    description: '#e0c3fc → #8ec5fc',
    icon: <GiDesk />,
    css: 'linear-gradient(135deg, #242423 0%, #242423 100%)',
    height: 400,
  },
  {
    name: 'Coming Soon',
    description: '#e0c3fc → #8ec5fc',
    icon:<Gi3DGlasses />,
    css: 'linear-gradient(135deg, #242423 0%, #242423 100%)',
    height: 400,
  }];
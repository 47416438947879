import rick from './rick.mp4';
import {useState} from 'react';
import { projects } from './projects';
import ReactPlayer from 'react-player'
import styles from './styles.module.css'
import useWindowDimensions from './useWindowDimensions';
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from '@react-spring/web'
import './App.css';

function App() {
  const [open, set] = useState(false)
  const { height: viewPortHeight , width: viewPortWidth } = useWindowDimensions();

  const rickDiameter = viewPortWidth > viewPortHeight ? '40vw' : '80vw';
  const projectListSize = viewPortWidth > viewPortHeight ? '50vw' : '90vw';

  const springApi1 = useSpringRef();
  const springApi2 = useSpringRef();
  const springApiVideo = useSpringRef();

  const { ...layoutStyle } = useSpring({
    ref: springApi1,
    config: config.stiff,
    from: { borderRadius: '50%'},
    to: {
      borderRadius: open ? '0' : '50%',
    },
  })

  const { size, ...rest } = useSpring({
    ref: springApi2,
    config: config.stiff,
    from: { size: rickDiameter, background: '#B2B09B', padding: "0px"},
    to: {
      size: open ? projectListSize : rickDiameter,
      padding: open ? "25px": "0px"
    },
  })

  const videoSpring = useSpring({
    ref: springApiVideo,
    config: config.stiff,
    from: { opacity: 0, cursor: "pointer" },
    to: {
      opacity: !open ? 1 : 0, // This controls the video's opacity
      display: !open ? 'block' : 'none', // Hide the video during the opening animation
      cursor: "pointer"
    },
    delay: open ? 0 : 1000, // Delay the appearance of the video at the end of the closing animation
  });


  const transApi = useSpringRef()
  const transition = useTransition(open ? projects : [], {
    ref: transApi,
    trail: 400 / projects.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  })


  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApiVideo, springApi1, springApi2, transApi] : [transApi, springApi2, springApi1, springApiVideo], [
    0,
    open ? 0.1 : 0.6,
  ])


  return (

      <div className={styles.wrapper}>
        <animated.div
          style={{ ...rest, ...layoutStyle, width: size, height: size }} 
          className={styles.container} 
          onClick={() => set(open => !open)}>

            {transition((style, item) => (
            <animated.div
              className={styles.item}
              style={{ ...style, background: item.css }}
            >
              <div>{item.icon}</div>
              <p/>
              <div>{item.name}</div>
            </animated.div>

          ))}

          <animated.div style={videoSpring}>
            <ReactPlayer url={rick} loop={true} playing={true} width={rickDiameter} height={rickDiameter} playsinline={true} muted/> 
          </animated.div>
        </animated.div>
      </div>
  );
}


export default App;
